import * as React from 'react';
import { Router } from '@reach/router';
import loadable from '@loadable/component'
// import Events from '../components/Events/Events';
// import Event from '../components/Events/Event';
const Events = loadable(() => import('../components/Events/Events'));
const Event  = loadable(() => import('../components/Events/Event'));

const TheEvents = () => (
  <Router>
    <Events path="/evenements" />
    <Event path="/evenements/:id/:title" />
  </Router>
);

export default TheEvents;